// Mensajes de error y éxito
.wpcf7-not-valid { border: 1px solid $input_borde_error; }

.wpcf7-response-output {
  background-color: $mensaje_correcto_background;
  border-color: $mensaje_correcto_color;
  color: $mensaje_correcto_color;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 19px 15px 17px 15px;
  text-align: center;
}

.wpcf7-validation-errors {
  background-color: transparent;
  border-color: $input_borde_error;
  color: $input_borde_error;
}

.wpcf7-not-valid-tip {
  color: #ff0000;
  font-size: 12px;
  line-height: 1.2;
  margin-top: -14px;
}

label {
  line-height: 30px;
  margin-bottom: 3px;
}

input,
textarea,
select,
.form-control {
  background-color: $blanco;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  color: #666666;
  min-height: 50px;

  &:focus {
    border-color: $verde;
    border-width: 1px;
    box-shadow: none;
    color: #666666;
  }
}

.form-control {
  margin-bottom: 15px;
  position: relative;
}

form.form-contacto {
  button[type='submit'],
  input[type='submit'],
  input[type='button'] {
    @include botonGeneral;
    @include backgroundImg('icono-flecha-boton-blanco.svg');

    background-color: $verde;
    border-color: $verde;
    color: $blanco;
    display: block;  
    margin-top: 25px;
    width: 100%;

    &::after {
      background-color: $blanco;
    }

    &:hover,
    &:focus {
      @include backgroundImg('icono-flecha-boton-verde.svg');

      background-color: $blanco;
      color: $color-principal;

      &::after {
        background-color: $verde;
      }
    }
  }

  &.wpcf7-form.submitting .ajax-loader {
    display: block;
  }

  &.wpcf7-form .ajax-loader {
    background-color: rgba($blanco, 0);
    background-image: url(img/loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    height: 25px;
    margin: 30px auto 0;
    width: 25px;

    &::before {
      content: none;
    }
  }
  
  .wpcf7-response-output {
    @include foundersGroteskRegular;
    
    background-color: $blanco;
    border-radius: 3px;
    color: #ff0000;
    font-size: 13px;
    padding: 17px 20px 16px;
  }

  &.sent .wpcf7-response-output {
    color: #46b450;
  }

  .wpcf7-not-valid {
    border-color: #ff0000;

    &:focus {
      border-color: $verde;
    }
  }

  .custom-file {
    height: 100%;
  }

  .custom-file-input {
    min-height: 0;
    position: absolute;
    z-index: -1;

    &:focus~.custom-file-label {
      border-color: rgba($blanco, 0);
      box-shadow: none;
      opacity: 0.6;
    }
  }

  .custom-file-label {
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: center;
    margin: 0;
    min-height: 45px;
    padding-bottom: 9px;
    padding-top: 55px;
    position: relative;
    text-align: center;
    transition: 0.5s;


    &::after {
      @include foundersGroteskRegular;

      background-color: #EAEAEA;
      border-radius: 3px;
      color: $negro;
      height: 100%;
      max-height: 45px;
      padding-bottom: 10px;
      padding-top: 10px;
      right: auto;
      text-align: center;
      width: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .curriculum~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }

  .custom-file-input + .wpcf7-not-valid-tip {
    display: block;
    margin-bottom: 14px;
  }
}

.wpcf7 {
  .form-contacto .wpcf7-response-output,
  .form-contacto.spam .wpcf7-response-output,
  .form-contacto.aborted .wpcf7-response-output,
  .form-contacto.failed .wpcf7-response-output,
  .form-contacto.invalid .wpcf7-response-output,
  .form-contacto.unaccepted .wpcf7-response-output {
    border-color: #ff0000;
    margin: 30px 0 0;
  }
}

//Tablet
@media (min-width: $break-md-min) {
  form.form-contacto {
    button[type='submit'],
    input[type='submit'] {
      width: 235px;
    }
  
    .custom-file-label {
      justify-content: flex-start;
      padding-bottom: 10px;
      padding-left: 202px;
      padding-top: 10px;
      text-align: left;

      &::after {
        left: 0;
        right: auto;
        width: 185px;
      }
    }
  }
}
