.ministries-container {
  overflow: hidden;
  padding: $separacion-bloques-sm 0;
  position: relative;

  .container {
    position: relative;
  }

  .grid-lines {
    top: -$separacion-bloques-sm;
  }

  h1 {
    @include encabezadoH2;

    color: $negro;
    margin-bottom: 20px;
  }

  .sidebar-left-heading {
    color: $negro;
  }
}

.events-upcoming {
  margin-top: 30px;

  &_title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    h3 {
      @include foundersGroteskRegular;
      @include encabezadoH2;

      color: $negro;
      margin-bottom: 0;
    }
  }

  &_item {
    align-items: center;
    display: flex;
    margin-bottom: 12px;
  }

  &_date {
    align-items: center;
    background-color: $verde-opaco;
    color: $blanco;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 70px;
    padding: 10px 13px 7px 13px;
  }

  &_month {
    font-size: 13px;
    letter-spacing: 0.14em;
    line-height: 16px;
    text-transform: uppercase;
  }

  &_day {
    font-size: 26px;
    line-height: 30px;
  }

  &_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;

    h3 {
      @include foundersGroteskRegular;

      color: $negro;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 0;
    }
  }

  &_cat {
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 21px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }
}

.bloque-cta {
  background-color: $fondo3;
  margin-bottom: 30px;
  padding: 30px 15px;
  text-align: center;

  h6 {
    @include encabezadoH3;

    color: $verde;
  }

  p {
    @include interRegular;

    color: $verde;
    font-size: 18px;
    line-height: 27px;
  }
}

.bloque-team {
  h3 {
    @include foundersGroteskRegular;
    @include encabezadoH2;

    color: $negro;
    margin-bottom: 30px;
  }

  .card-person {
    background-color: $blanco;
    box-shadow: 0px 0px 20px 0px rgba($negro, 0.14);
    margin-bottom: 30px;

    &_img {
      background-color: $fondo3;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &::before {
        content: '';
        display: block;
        padding-top: 80%;
      }
    }

    &_body {
      padding: 16px 20px 23px;

      h5 {
        @include foundersGroteskSemiBold;

        color: $negro;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
      }

      span {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .link-mail,
  .link-phone {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 12px;
    display: block;
    padding-left: 20px;
    text-decoration: underline;
  }

  .link-mail {
    background-image: url('img/icono-correo.svg');
  }

  .link-phone {
    background-image: url('img/icono-telefono.svg');
  }
}

.bloque-faqs {
  h3 {
    @include foundersGroteskRegular;
    @include encabezadoH2;

    color: $negro;
    margin-bottom: 27px;
  }
}

@include breakpoint('tablet'){
  .events-upcoming {
    margin-bottom: 22px;
  
    &_title {
      margin-bottom: 35px;
    }
  }

  .bloque-team {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
      width: 100%;
    }
  
    .card-person {
      width: 47%;
    }
  }
}

@include breakpoint('desktop-lg'){
  .ministries-container {
    padding: 57px 0;
  
    .grid-lines {
      top: -57px;
    }
  
    h1 {
      margin-bottom: 34px;
    }

    .ministries-content {
      padding: 0 60px 0;
    }
  }

  .bloque-cta {
    margin-bottom: 27px;
    padding: 58px 25px 60px;
  
    h6 {
      @include encabezadoH2;
    }
  }
}

@include breakpoint('desktop-xl'){
  .ministries-container {
    .ministries-content {
      padding: 0 80px 0;
    }
  }
}
