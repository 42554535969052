.contenido-administrable {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    margin-top: 26px;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    @include p22MackinacRegular;
    @include backgroundImg('icono-quotes.svg');

    font-size: 16px;
    line-height: 30px;
    color: $negro;
    background-color: $fondo2;
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: right top;
    padding: 26px 55px 26px 25px;

    p {
      margin-bottom: 7px;
    }

    em:last-child {
      @include foundersGroteskRegular;

      letter-spacing: 0.1em;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      font-style: normal;
      color: $verde-opaco;

      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 1px;
        position: relative;
        top: -4px;
        left: 0;
        margin-right: 10px;
        background-color: $verde-opaco;
      }
    }
  }

  a {
    &:visited {
      color: $negro;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    margin-top: 10px;
  }
}

//Tablet
@media (min-width: $break-sm-min) {
  .contenido-administrable {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 25px;
      margin-top: 34px;
    }

    blockquote {
      font-size: 18px;
      line-height: 32px;
      padding: 26px 95px 26px 32px;
    }
  }
}

//Desktop LG
@media (min-width: $break-lg-min) {
  .contenido-wrapper {
    padding-bottom: $separacion-bloques-lg;
    padding-top: $separacion-bloques-lg;

    .bloque-general {
      padding-bottom: $separacion-bloques-lg / 2;
      padding-top: $separacion-bloques-lg / 2;
    }
  }

  .contenido-administrable {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 30px;
      margin-top: 39px;
    }
  }
}
