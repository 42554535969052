.bloque-general {
  overflow: hidden;
  position: relative;
  padding: $separacion-bloques-sm 0;
  position: relative;

  > .container {
    position: relative;

    .grid-lines {
      top: -$separacion-bloques-sm;
    }

    .grid-lines__line {
      height: calc(100% + #{$separacion-bloques-sm});
    }
  }
}

.contenido-wrapper {
  padding: $separacion-bloques-sm 0;

  .bloque-general {
    padding-bottom: $separacion-bloques-sm / 2;
    padding-top: $separacion-bloques-sm / 2;
  }

  .bloque-general:first-child {
    padding-top: 0;
  }

  .bloque-general:last-child {
    padding-bottom: 0;
  }
}

.grid-lines {
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  max-width: 100%;
  padding: 0 calc(1.5rem * .5);
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 50;

  &__line {
    border-left: 1px solid rgba(119, 139, 133, 0.1);
    height: 100vh;
    width: 50%;
  }

  &__line:nth-child(even) {
    border-right: 1px solid rgba(119, 139, 133, 0.1);
  }
}

.bloque-subtitulo {
  @include foundersGroteskRegular;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $gris;
  padding-left: 35px;
  display: block;
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    background-color: $gris;
    width: 23px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bloque-titulo {
  color: $negro;
  margin-bottom: 20px;
}

@include breakpoint('tablet') {
  .bloque-general {
    padding: $separacion-bloques-lg 0;

    > .container {
      .grid-lines {
        top: -$separacion-bloques-lg;
      }

      .grid-lines__line {
        height: calc(100% + #{$separacion-bloques-lg});
      }
    }
  }

  .contenido-wrapper {
    padding: $separacion-bloques-lg 0;

    .bloque-general {
      padding-bottom: $separacion-bloques-lg / 2;
      padding-top: $separacion-bloques-lg / 2;
    }
  }

  .bloque-subtitulo {
    margin-bottom: 28px;
  }

  .bloque-boton-der {
    display: flex;
    justify-content: flex-end;
  }
}

@include breakpoint('desktop-lg') {
  .container,
  .grid-lines {
    padding: 0 30px;
  }

  .bloque-general {
    padding: $separacion-bloques-xl 0;

    > .container {
      .grid-lines {
        top: -$separacion-bloques-xl;
      }

      .grid-lines__line {
        height: calc(100% + #{$separacion-bloques-xl});
      }
    }
  }

  .contenido-wrapper {
    padding: $separacion-bloques-xl 0;

    .bloque-general {
      padding-bottom: $separacion-bloques-xl / 2;
      padding-top: $separacion-bloques-xl / 2;
    }
  }

  .grid-lines {
    &__line {
      width: 20%;
    }

    &__line:last-child {
      border-right: 1px solid rgba(119, 139, 133, 0.1);
    }

    &__line:nth-child(even) {
      border-right: none;
    }
  }
}
