.footer {
  color: $blanco;
  background-color: #205353;
  padding: 40px 0;
  position: relative;
  overflow: hidden;

  .grid-lines {
    top: -40px;
  }

  .grid-lines__line,
  .grid-lines__line:last-child,
  .grid-lines__line:nth-child(even) {
    border-color: rgba($blanco, 0.1);
  }

  &__logo {
    order: 2;

    img {
      max-width: 50%;
    }
  }

  &__menu,
  &__social {
    max-width: 50%;
    width: 100%;
  }

  &__menu--no-title {
    order: 1;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  a {
    @include foundersGroteskRegular;
    color: $blanco;
  }

  a:hover {
    opacity: 0.5;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 5px 0;
  }

  .icono-social {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 22px;
    margin-bottom: 20px;
    width: 22px;
  }

  .icono-facebook {
    @include backgroundImg('icono-facebook.svg');
  }

  .icono-twitter {
    @include backgroundImg('icono-twitter.svg');
  }

  .icono-instagram {
    @include backgroundImg('icono-instagram.svg');
  }
}

@include breakpoint('tablet') {
  .footer {
    padding: $separacion-bloques-sm 0;

    .grid-lines {
      top: -$separacion-bloques-sm;
    }
  }
}

@include breakpoint('desktop-lg') {
  .footer {
    padding: 60px 0;

    .grid-lines {
      top: -60px;
    }

    &__logo,
    &__menu {
      max-width: 20%;
    }

    &__logo {
      margin-right: auto;
      order: 0;

      img {
        max-width: 100%;
      }
    }

    &__menu--no-title {
      order: 0;
      padding-top: 44px;
    }

    &__social {
      margin-right: -23px;
      width: auto;
    }
  }
}
