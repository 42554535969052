.breadcrumbs {
  list-style: none;
  padding-left: 0;
  margin-bottom: 7px;
  display: flex;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  li:not(:last-child) {
    &::after {
      content: '/';
      margin: 0 12px;
    }
  }
}
