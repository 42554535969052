.bloque-news-detail > .container {
  display: flex;
  flex-wrap: wrap;
}

.news-detail {
  &_columna-contenido {
    width: 100%;
  }

  &_columna-sidebar {
    padding-top: 23px;
    max-width: 330px;
    width: 100%
  }

  &_heading {
    @include encabezadoH2;

    color: $negro;
    margin-bottom: 10px;
  }

  &_date {
    margin-bottom: 27px;
  }
}

.related-news_item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &-img-link {
    flex-shrink: 0;
    height: 110px;
    margin-right: 20px;
    overflow: hidden;
    width: 100px;

    &:hover .related-news_item-img {
      transform: scale(1.1);
    }
  }

  &-img {
    @include backgroundGeneral;

    background-size: cover;
    height: 100%;
    transition: 0.5s;
    width: 100%;
  }

  &-date {
    color: $gris;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 21px;
    text-transform: uppercase;
  }

  &-title {
    color: $negro;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@include breakpoint('desktop-lg') {
  .news-detail {
    &_columna-contenido {
      max-width: 60%;
    }

    &_columna-sidebar {
      padding-top: 0;
      margin-left: 30px;
    }

    &_date {
      margin-bottom: 32px;
    }
  }
}

@include breakpoint('desktop-xl') {
  .news-detail {
    &_columna-contenido {
      max-width: 70%;
    }

    &_columna-sidebar {
      max-width: 294px;
    }
  }
}

@include breakpoint('desktop-xxl') {
  .news-detail {
    &_columna-contenido {
      max-width: calc(80% - 60px);
    }

    &_columna-sidebar {
      position: absolute;
      max-width: 300px;
      right: -19px;
      top: 0;
      margin-left: 0;
    }
  }
}
