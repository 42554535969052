.bloque-grid-links {
  position: relative;
  overflow: hidden;
  padding: $separacion-bloques-sm 0;

  .container {
    position: relative;
  }

  .grid-lines {
    top: -$separacion-bloques-sm;
  }

  .bloque-grid-links_wrapper {
    margin-top: 30px;
  }

  .grid-link_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $acento2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 32px;
    color: $blanco;
    text-align: center;
    height: 300px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($negro, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      transition: 0.5s;
    }

    &:hover::before {
      background-color: rgba($negro, 0.5);
    }

    .grid-link_title {
      @include foundersGroteskRegular;

      font-size: 28px;
      line-height: 37px;
      display: block;
      max-width: 140px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    .link-flecha {
      @include foundersGroteskRegular;

      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      display: block;
      position: relative;
      padding-right: 20px;

      &::after {
        content: '';
        display: block;
        width: 15px;
        height: 10px;
        background-image: url('img/icono-flecha-boton-blanco.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@include breakpoint('tablet') {
  .bloque-grid-links {
    padding: $separacion-bloques-lg 0;

    .grid-lines {
      top: -$separacion-bloques-lg;
    }

    .bloque-titulo {
      margin-bottom: 0;
    }

    .bloque-grid-links_wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .grid-link_item {
      width: 50%;
      height: 350px;

      &:nth-child(3n) {
        width: 100%;
      }
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-grid-links {
    padding: $separacion-bloques-xl 0;

    .grid-lines {
      top: -$separacion-bloques-xl;
    }

    .grid-link_item {
      width: 20%;
      height: 400px;

      &:nth-child(3n) {
        width: 20%;
      }
    }
  }
}

@include breakpoint('desktop-xl') {
  .bloque-grid-links {
    .grid-link_item {
      .link-flecha {
        padding-right: 27px;
      }
    }
  }
}
