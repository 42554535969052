.hero-home {
  background-color: $fondo2;
  overflow: hidden;
  padding: 110px 0 217px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $blanco;
  position: relative;
  z-index: -1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($negro, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .container {
    position: relative;
  }

  .grid-lines {
    top: -110px;

    &__line {
      border-color: rgba(119, 139, 133, 0.2);
    }
  
    &__line:nth-child(even) {
      border-color: rgba(119, 139, 133, 0.2);
    }
  }

  .bloque-subtitulo {
    color: $blanco;
    margin-bottom: 17px;

    &::before {
      background-color: $blanco;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  b {
    @include p22MackinacBold;
  }

  .hero-home_desc {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 27px;
  }
}

.hero_events-slider {
  background-color: $blanco;
  color: $negro;
  position: absolute;
  z-index: 51;
  bottom: 0;
  width: 100%;
  padding: 16px 13px;

  &-title {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: block;
  }

  .hero_events-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hero_events-date {
    padding: 12px 15px 7px 18px;
    background-color: $verde-opaco;
    color: $blanco;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_month {
      font-size: 17px;
      line-height: 18px;
      letter-spacing: 0.12em;
      display: flex;
      text-transform: uppercase;
    }

    &_day {
      font-size: 42px;
      line-height: 43px;
      letter-spacing: 0.12em;
      display: flex;
    }
  }

  .hero_events-body {
    padding: 10px 12px;

    span {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: $gris;
      text-transform: uppercase;
      display: block;
      margin-bottom: 2px;
    }

    h3 {
      @include foundersGroteskRegular;

      margin-bottom: 0;
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}

@include breakpoint('tablet-sm') {
  .hero_events-slider {
    width: 410px;
    left: auto;
    right: 0;
    padding: 12px 30px 4px;

    &-title {
      margin-bottom: 10px;
    }
  }
}

@include breakpoint('tablet') {
  .hero-home {
    padding: 160px 0 145px;
  
    .grid-lines {
      top: -160px;
    }
  
    h1 {
      @include encabezadoH1;

      margin-bottom: 25px;
      max-width: 820px;
    }
  
    .hero-home_desc {
      margin-bottom: 26px;
    }
  }
}

@include breakpoint('desktop-lg') {
  .hero-home {
    padding: 230px 0 148px;
  
    .grid-lines {
      top: -230px;
    }
  }
}
