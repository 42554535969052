.menu__logo {
  max-height: 44px;
  width: auto;
}

.menu-principal-wrapper {
  background-color: $verde;
  padding: 5px 15px 10px 15px;

  > .navbar {
    flex-wrap: nowrap;
  }
}

#search-modal,
.menu-item__search-desktop {
  display: none;
}

.dropdown-toggle::after {
  content: none;
}

.menu-item-desktop {
  display: none;
}

.menu-principal-transparente {
  background-color: rgba(255,255,255,0);
  border-bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

@include breakpoint('tablet') {
  .menu-principal-wrapper {
    padding: 5px 30px 10px 30px;
  }
}

@include breakpoint('desktop-lg') {
  header {
    left: 0;
    position: relative;
    top: 0;
  }

  .menu-item--formulario-buscar-xs {
    display: none;
  }

  .menu-principal-wrapper {
    background-color: $verde;
    padding: 5px 30px 10px 70px;
    position: relative;

    &.menu-principal-transparente {
      background-color: rgba(255,255,255,0);
      position: absolute;
    }

    #button-open-menu-mobile {
      display: none;
    }

    > .navbar {
      flex-wrap: wrap;
    }
  }

  .menu-item-desktop {
    display: block;
  }

  .menu-item-mobile {
    display: none;
  }

  .menu-principal {
    color: $blanco;
    justify-content: flex-end;
    padding-top: 3px;
  }

  .menu-principal__items > .menu-item:not(:last-child) {
    margin-right: 10px;
  }

  .menu-principal__items > .menu-item > a {
    border: 1px solid rgba($negro, 0);
    color: $blanco;
    display: inline-block;
    padding: 7px 10px 7px;

    &:hover {
      opacity: 0.5;
    }
  }

  .menu-item__search-desktop {
    display: block;
  }

  .menu-item__search-desktop a::before {
    @include backgroundImg('icono-lupa-blanco.svg');
    @include backgroundGeneral;

    background-size: contain;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 10px;
    position: relative;
    top: 3px;
    width: 16px;
  }

  .menu-item__button {
    margin-left: 10px;
  }

  .menu-principal__items > .menu-item__button a {
    background-color: $acento2;
    border-color: $acento2;

    &:hover {
      background-color: rgba($negro, 0);
      opacity: 1;
    }
  }

  .menu-item__language {
    align-items: center;
    display: flex;

    span {
      display: none;
    }

    ul {
      display: flex;
      padding-left: 0;
    }

    li {
      &:not(:last-child)::after {
        content: '/';
        display: inline-block;
        margin-left: 3px;
        margin-right: 5px;
      }

      &.active a {
        @include foundersGroteskSemiBold;

        color: $blanco;

        &:hover {
          color: rgba($blanco, 0.8);
        }
      }
    }

    a {
      color: rgba($blanco, 0.5);
      display: inline-block;
      font-size: 14px;
      line-height: 17px;

      &:hover {
        color: rgba($blanco, 0.8);
      }
    }
  }

  .menu-principal:not(.mm-menu) .menu-item {
    .menu-principal__submenu {
      border: 0;
      min-width: 400px;
      opacity: 0;
      padding-top: 22px;
      position: absolute;
      top: calc(100%);
      transform: translateY(-10px);
      transition: .3s ease;
      visibility: hidden;
      z-index: 100;

      .submenu-container {
        background: $blanco;
        box-shadow: 0 0 20px rgba($negro, 0.08);
        max-height: 650px;
        overflow: auto;
        padding: 60px;
      }

      a {
        color: $negro;
        display: block;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 13px;
        padding-top: 5px;

        &:hover,
        &:focus {
          opacity: 0.5;
        }
      }
    }

    .menu-principal__submenu-ministries {
      width: 620px;

      span {
        @include p22MackinacRegular;

        color: $gris;
        display: block;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 28px;
      }

      ul {
        column-count: 2;
        column-gap: 100px;
        padding-left: 0;
      }

      li {
        break-inside: avoid-column;
      }
    }

    .dropdown-toggle {
      &:hover + .menu-principal__submenu,
      + .menu-principal__submenu:focus,
      + .menu-principal__submenu:hover {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        z-index: 2000;
      }
    }
  }

  .dropdown-toggle::after {
    @include backgroundImg('icono-dropdown-toggle-blanco.svg');
    @include backgroundGeneral;

    background-size: 11px;
    border: 0;
    content: '';
    height: 8px;
    vertical-align: baseline;
    width: 12px;
  }

  #search-modal {
    align-items: center;
    background-color: $blanco;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(-85px);
    transition: 0.3s;
    visibility: hidden;
    width: 100%;

    &.open {
      transform: translateY(0);
      visibility: visible;
    }

    .modal-close-button {
      color: $gris;
      display: flex;
      margin-left: 54px;
      padding: 8px;

      &:hover {
        opacity: 0.5;
      }

      &::before {
        @include backgroundImg('icono-cerrar-gris.svg');
        @include backgroundGeneral;

        background-size: 14px;
        content: '';
        display: inline-block;
        height: 18px;
        margin-right: 10px;
        position: relative;
        top: 5px;
        width: 18px;
      }
    }
  }

  .search-modal__container {
    align-items: center;
    display: flex;
  }

  .search-form {
    align-items: center;
    display: flex;
    width: 100%;

    .search-icon {
      margin-right: 10px;
      max-height: 15px;
      width: auto;
    }

    button[type='submit'] {
      background-color: $acento2;
      color: $blanco;
      line-height: 22px;
      padding: 11px 30px 10px;

      &:hover,
      &:focus {
        background-color: $acento1;
      }
    }

    .input-wrapper {
      margin-right: 30px;
      width: 100%;
    }

    .form-control {
      border: 0;
      color: $gris;
      font-size: 18px;
      height: 44px;
      line-height: 22px;
      margin-bottom: 0;
      min-height: 0;
      padding: 0;

      + .input-line {
        background-color: $negro;
        bottom: 1px;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: relative;
        transition: 0.3s ease-in-out;
        width: 0%;
      }

      &:focus {
        box-shadow: none;

        + .input-line {
          width: 100%;
        }
      }
    }
  }

  // Menu modal desktop
  .page-wrapper {
    overflow: hidden;

    &::after {
      background-color: rgba($negro, 0.5);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: 0.3s;
      visibility: hidden;
      width: 100%;
    }
  }

  body.menu--modal-open {
    overflow: hidden;

    .page-wrapper {
      &::after {
        opacity: 1;
        visibility: visible;
      }
      
      .menu-principal-transparente {
        z-index: auto;
      }
    }  

    .menu-item__button,
    #button-open-menu-desktop,
    .menu-item__language {
      color: $gris;
      z-index: 2;
    }

    .menu-item__button a:hover {
      color: $acento2;
    }

    .menu-item__language {
      li.active a {
        @include foundersGroteskSemiBold;

        color: $gris;

        &:hover {
          color: rgba($gris, 0.8);
        }
      }

      a {
        color: rgba($gris, 0.5);

        &:hover {
          color: rgba($gris, 0.3);
        }
      }
    }
  }

  #button-open-menu-desktop {
    margin-left: 10px;
    margin-right: 10px;
  }

  .menu-principal__items.menu--modal {
    align-items: flex-start;
    background-color: #F5FAF8;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    justify-content: flex-start;
    overflow: auto;
    padding: 160px 40px 40px 205px;
    position: absolute;
    right: -30px;
    top: -5px;
    transform: translateX(100%);
    transition: .3s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    width: 825px;
    z-index: 1;

    &.menu--open {
      transform: translateX(0);
    }

    .menu-item {
      &:not(.menu-item-sociales) {
        margin-right: 0;
        max-width: 205px;
        width: 100%;
      }

      a {
        color: $negro;
        display: block;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
        padding: 0 50px 0 0;
        position: relative;
      }

      &.menu-item-sociales {
        position: absolute;
        right: 185px;
        top: 160px;

        ul {
          padding-left: 0;
        }

        a {
          margin-bottom: 8px;
          padding: 0;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .menu-item-dropdown {
      position: relative;

      &:hover > a {
        color: $acento1;

        &::after {
          transform: rotateZ(180deg);
        }
      }

      > a {
        &::after {
          @include backgroundImg('icono-menu-lateral-gris.svg');
          @include backgroundGeneral;

          background-size: 6px 13px;
          content: '';
          display: inline-block;
          height: 13px;
          position: absolute;
          right: 20px;
          top: 14px;
          transition: 0.5s;
          width: 13px;
        }
      }

      > a:hover + .menu-item-submenu,
      .menu-item-submenu:hover {
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
        z-index: 1;
      }
    }

    .menu-item-submenu {
      left: 100%;
      max-width: 205px;
      opacity: 0;
      padding-left: 0;
      padding-top: 3px;
      position: absolute;
      top: 0;
      transform: translateX(-20px);
      transition: 0.3s;
      visibility: hidden;
      width: 100%;
      z-index: 0;

      li {
        padding-right: 20px;
      }

      a {
        color: $gris;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 18px;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

@include breakpoint('desktop-xl') {
  .menu-principal__items > .menu-item > a {
    padding: 7px 30px 7px;
  }

  .menu-item__button {
    margin-left: 32px;
  }

  #button-open-menu-desktop {
    margin-left: 25px;
    margin-right: 15px;
  }
}
