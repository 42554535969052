:root {
  --mb-button-size: 60px;
  --mb-bar-width: 0.3;
  --mb-bar-height: 2px;
  --mb-bar-spacing: 7px;
}

#mm-button-open-menu-desktop {
  display: none;
}

#button-open-menu-mobile,
#button-open-menu-desktop {
  align-items: center;
  color: $blanco;
  display: flex;
  padding: 8px;

  img {
    margin-right: 10px;
    max-height: 16px;
    width: auto;
  }

  &:hover {
    opacity: 0.5;
  }

  .span-close {
    display: none;
  }
}

#button-open-menu-mobile {
  padding: 12px 0 4px;
}

.mm-panel {
  background-color: $fondo2;
}

.mm-listitem {
  &:after {
    border-color: rgba(#ddd, 0.5);
    left: 0;
  }

  a {
    &:hover {
      background-color: $fondo3;
    }
  }

  &.menu-item-sociales {
    display: flex;

    li {
      flex-grow: 1;
    }

    a {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 15px 20px;
    }
  }
}

.buscar-form--mobile {
  display: block;
  position: relative;
  width: 100%;

  .form-control {
    background-color: $fondo2;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $negro;
    line-height: 24px;
    margin: 0;
    min-height: 50px;
    padding: 10px 15px 10px 52px;
    transition: 0.5s;

    &:focus {
      background-color: $fondo3;
    }
  }

  button[type="submit"] {
    @include backgroundImg('icono-lupa-gris.svg');

    background-position: center 7px;
    background-repeat: no-repeat;
    background-size: 15px;
    height: 30px;
    left: 12px;
    overflow: hidden;
    position: absolute;
    text-indent: -1000px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
  }
}

.mm-menu {
  .mm-navbar {
    background-color: #FFFFFF;
    border-color: #EBEBEB;
    color: #000000;
    display: block;
    height: 60px;
  }

  .mm-panel:first-child .mm-navbar {
    display: none;
  }

  .mm-panels > .mm-panel:not(:first-child) > .mm-navbar + .mm-listview,
  .mm-panels > .mm-panel:not(:first-child) > .mm-navbar + .submenu-container {
    margin-top: 40px;
  }

  .mm-navbar + .submenu-container {
    > span {
      display: none;
    }

    ul {
      list-style: none;
      margin: 20px -20px 0;
      margin-bottom: 0;
      padding-left: 0;
    }

    .menu-item {
      width: 100%;

      a {
        display: block;
        padding: 12px 20px;
        position: relative;

        &::after {
          border-bottom-style: solid;
          border-bottom-width: 1px;
          border-color: rgba(221, 221, 221, 0.5);
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .menu-item__button {
    padding: 18px 20px;
  
    a {
      background-color: $acento2;
      border: 1px solid $acento2;
      color: $blanco;
      padding: 10px 30px 12px;
      text-align: center;
  
      &:hover {
        background-color: $blanco;
        color: $acento2;
      }
    }
  }
}

.mm-navbar .mm-navbar__title {
  padding: 20px;
}


.mm-panels>.mm-panel>.mm-listview {
  margin-bottom: 0;
  margin-top: 0;
}

.mm-btn.mm-btn_next {
  &::after {
    @include backgroundImg('icono-menu-lateral-gris.svg');
    @include backgroundGeneral;

    background-size: 6px;
    border: 0;
    height: 20px;
    right: 18px;
    transform: none;
    width: 20px;
  }
}

.mm-btn.mm-btn_prev::before {
  @include backgroundImg('icono-menu-lateral-gris.svg');
  @include backgroundGeneral;

  background-size: 6px;
  border: 0;
  height: 20px;
  transform: rotateZ(180deg);
  width: 20px;
}
