.bloque-slider-events {
  background-color: $fondo2;
  overflow: hidden;
  padding: $separacion-bloques-sm 0;
  position: relative;

  .container {
    position: relative;
  }

  .grid-lines {
    top: -$separacion-bloques-sm;
  }

  .bloque-titulo {
    margin-bottom: 0;
  }

  .bloque-boton {
    margin-top: 30px;
  }
}

.slider-events .slick-track {
  display: flex;
}

.slider-events .slick-slide {
  height: inherit;
}

.slider-events.slider-general .slick-dots {
  margin-top: 0;
}

.slide-event {
  background-color: $blanco;
  box-shadow: 0px 0px 20px 0px rgba($negro, .08);

  &_img {
    background-color: #979797;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: '';
      display: block;
      padding-top: 70%;
    }
  }

  &_date {
    align-items: center;
    background-color: $verde-opaco;
    color: $blanco;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    padding: 10px 11px;
    text-align: center;
    width: 60px;
  }

  &_month {
    display: block;
    font-size: 13px;
    letter-spacing: 1.4px;
    line-height: 14px;
    text-transform: uppercase;
  }

  &_day{
    display: block;
    font-size: 27px;
    letter-spacing: 1px;
    line-height: 28px;
  }

  &_body {
    padding: 25px 30px;

    span {
      color: $gris;
      display: block;
      font-size: 14px;
      letter-spacing: 0.5px;
      letter-spacing: 0.1em;
      line-height: 21px;
      text-transform: uppercase;
    }

    h3 {
      @include foundersGroteskRegular;

      color: $negro;
      font-weight: 400;
      line-height: 34px;
      margin-bottom: 0;
    }
  }
}

@include breakpoint('tablet') {
  .bloque-slider-events {
    padding: $separacion-bloques-lg 0;
    overflow: hidden;

    .grid-lines {
      top: -$separacion-bloques-lg;
    }

    .bloque-boton {
      margin-top: 0;
    }
  }

  .slider-events .slick-list {
    overflow: visible;
  }

  .slide-event.slick-slide {
    display: flex;
    flex-direction: row;
  }

  .slide-event {
    margin-right: 30px;
  
    &_img {
      width: 40%;

      &::after {
        padding-top: 70%;
      }
    }
  
    &_body {
      padding: 40px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-slider-events {
    padding: $separacion-bloques-xl 0;

    .grid-lines {
      top: -$separacion-bloques-xl;
    }
  }

  .slider-events .slick-list {
    width: 66.7%;
  }

  .slide-event {
    margin-right: 60px;
  }
}

@include breakpoint('desktop-xl') {
  .slider-events .slick-list {
    width: 64.8%;
  }
}
