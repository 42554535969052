.bloque-news-brief {
  background-color: $fondo2;
  overflow: hidden;
  padding: $separacion-bloques-sm 0;
  position: relative;

  .container {
    position: relative;
  }

  .grid-lines {
    top: -$separacion-bloques-sm;
  }
}

.bloque-news_principal {
  background-color: #979797;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 20px 0px rgba($negro, 0.08);
  color: $blanco;
  display: block;
  margin-bottom: 20px;
  margin-top: 30px;
  padding: 20px 20px;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }

  &::after {
    background: linear-gradient(rgba($negro, 0), rgba($negro, 0.6));
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.5s;
    width: 100%;
    z-index: 0;
  }

  &:hover {
    color: rgba($blanco, 0.9);

    &::after {
      background-color: rgba($negro, 0.25);
    }
  }

  .news-principal_date,
  .news-principal_title {
    position: relative;
    z-index: 1;
  }

  .news-principal_date {
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 21px;
    text-transform: uppercase;
  }

  .news-principal_title {
    @include foundersGroteskRegular;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 0;
  }
}

.news-item {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    .news-item_img::after {
      background-color: rgba($negro, 0.25);
    }

    .news-item_title {
      color: rgba($negro, 0.6);
    }
  }

  &_img {
    background-color: #979797;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::after {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.5s;
      width: 100%;
      z-index: 0;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 70%;
    }
  }

  &_body {
    padding: 15px 0;
  }

  &_date {
    color: $gris;
    display: block;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 21px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  &_title {
    color: $negro;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 0;
    transition: 0.5s;
  }
}

@include breakpoint('tablet') {
  .bloque-news-brief {
    padding: $separacion-bloques-lg 0;

    .grid-lines {
      top: -$separacion-bloques-lg;
    }

    .bloque-titulo {
      margin-bottom: 0;
    }
  }

  .bloque-news_principal {
    margin-bottom: 0;
  }

  .bloque-news_items {
    margin-top: 30px;
  }

  .news-item {
    align-items: center;
    display: flex;
    flex-direction: row;

    &_img {
      width: 25%;

      &::before {
        padding-top: 100%;
      }
    }

    &_body {
      padding: 10px 20px;
      width: 75%;
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-news-brief {
    padding: $separacion-bloques-xl 0;

    .grid-lines {
      top: -$separacion-bloques-xl;
    }
  }

  .bloque-news_principal {
    &::before {
      padding-top: 50%;
    }
  }

  .news-item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
