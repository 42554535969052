.bloque-acordeon {
  box-shadow: 0px 0px 20px 0px #00000014;
  background-color: $blanco;
  margin-bottom: 32px;

  &__item {
    font-size: 22px;
    line-height: 33px;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba($verde-opaco, 0.2);
    }
  }

  &__link {
    padding: 29px 74px 28px 32px;
    position: relative;
    display: block;

    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      background-color: $verde;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('img/icono-menos.svg');
      background-position: center;
      background-size: inherit;
      background-repeat: no-repeat;
    }

    &.collapsed::after {
      background-image: url('img/icono-mas.svg');
      background-size: 14px;
    }
  }

  &__colapsable {
    padding: 0 32px 32px;

    .contenido-administrable {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
