.boton-principal {
  @include botonGeneral;
  @include botonPrincipal;
}

.boton-principal-blanco {
  @include botonGeneral;
  @include botonPrincipalBlanco;
}

.boton-principal-verde {
  @include botonGeneral;
  @include botonPrincipalVerde;
}
