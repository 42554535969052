/*
Theme Name: THEME_NAME
Theme URI: http://wordpress.org/themes/twentythirteen
Author: Nett
Author URI: http://nett.mx/
Description: THEME_DESCRIPTION
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: TAG_1, TAG_2, etc
Text Domain: twentythirteen
*/

@import "_config.scss";
@import "base/_base.scss";
@import "base/_boilerplate.scss";
@import "base/_form.scss";
@import "components/_botones.scss";
@import "components/_footer.scss";
@import "components/_header.scss";
@import "components/_menu-mobile.scss";
@import "components/blocks/_bloque-404.scss";
@import "components/blocks/_bloque-acordeon.scss";
@import "components/blocks/_bloque-grid-links.scss";
@import "components/blocks/_bloque-img-info.scss";
@import "components/blocks/_bloque-news-brief.scss";
@import "components/blocks/_bloque-slider-events.scss";
@import "components/blocks/_breadcrumbs.scss";
@import "components/blocks/_containers.scss";
@import "components/blocks/_contenido.scss";
@import "components/blocks/_gravity-forms.scss";
@import "components/blocks/_grid-lines.scss";
@import "components/blocks/_hero.scss";
@import "components/blocks/_paginacion.scss";
@import "components/blocks/_sidebar.scss";
@import "components/blocks/_slider.scss";
@import "components/views/_contact.scss";
@import "components/views/_home.scss";
@import "components/views/_ministries.scss";
@import "components/views/_news-detail.scss";
@import "components/views/_search-results.scss";
