.link-return {
  color: $gris;
  display: inline-block;
  margin-bottom: 22px;

  img {
    margin-right: 12px;
    max-height: 10px;
    position: relative;
    top: -1px;
    width: auto;
  }

  &:hover {
    color: $gris;
    opacity: 0.5;
  }
}

.bloque-search-results {
  position: relative;

  .container {
    display: flex;
    flex-wrap: wrap;
  }
}

.search-results {
  &_column,
  &_sidebar-column {
    width: 100%;
  }

  &_form {
    margin-bottom: 23px;

    .form-control {
      @include backgroundImg('icono-lupa-verde.svg');

      background-position: 12px 18px;
      background-repeat: no-repeat;
      background-size: 15px;
      font-size: 18px;
      line-height: 22px;
      padding: 14px 20px 14px 38px;
    }
  }

  &_heading {
    @include encabezadoH2;

    color: $negro;
    margin-bottom: 15px;
  }

  &_item {
    background-color: $blanco;
    box-shadow: 0 0 20px rgba($negro, 0.08);
    display: block;
    padding: 24px 30px 22px;

    &-type {
      color: $gris;
      display: block;
      font-size: 14px;
      letter-spacing: 0.1em;
      line-height: 21px;
      margin-bottom: 1px;
      text-transform: uppercase;
    }

    &-title {
      color: $negro;
      font-size: 28px;
      line-height: 34px;
    }

    &:hover {
      background-color: $verde;

      .search-results_item-type,
      .search-results_item-title {
        color: $blanco;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

@include breakpoint('tablet') {
  .search-results {
    &_column {
      max-width: calc(80% - 60px);
    }
  
    &_sidebar-column {
      margin-left: auto;
      max-width: 20%;
    }

    &_heading {
      margin-bottom: 32px;
    }

    &_item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
}
