.bloque-img-info {
  position: relative;
  overflow: hidden;
  padding: $separacion-bloques-sm 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  .grid-lines {
    top: -$separacion-bloques-sm;
  }

  .bloque-img-info_container {
    display: flex;
    flex-direction: column-reverse;
  }

  .bloque-img-info_info-wrapper {
    margin-bottom: 40px;

    p {
      margin-bottom: 25px;
    }
  }
}

@include breakpoint('tablet') {
  .bloque-img-info {
    padding: 50px 0;

    .grid-lines {
      top: -50px;
    }

    .bloque-img-info_container {
      flex-direction: row;
    }

    .bloque-img-info_info-wrapper,
    .bloque-img-info_img-wrapper {
      width: 50%;
    }

    .bloque-img-info_info-wrapper {
      padding-left: 30px;
      margin-bottom: 0;
    }

    .bloque-img-info_img-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-img-info {
    padding: 55px 0;

    .grid-lines {
      top: -55px;
    }

    .bloque-img-info_img-wrapper {
      width: 40%;
    }

    .bloque-img-info_info-wrapper {
      width: 60%;
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

