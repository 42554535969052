.slider-general {
  &.slick-slider {
    margin-bottom: 0;
  }

  .slick-track {
    padding: 30px 0;
  }

  .slick-slide {
    outline: none;
  }

  .slick-arrow {
    @include slickArrows;

    top: -18px;
  }

  .slick-prev {
    @include slickArrowPrev('icono-flecha-anterior-slider.svg');

    left: auto;
    right: 49px;
  }

  .slick-next {
    @include slickArrowNext('icono-flecha-siguiente-slider.svg');

    right: 12px;
  }

  .slick-disabled {
    opacity: 0.5;
    pointer-events: none
  }

  .slick-dots {
    @include puntosSlider($verde);
  }

  &.slide-events_hero {
    .slick-track {
      padding: 20px 0;
    }

    .slick-arrow {
      top: 50%;
      background-color: $blanco;
    }
  
    .slick-prev {
      right: 0;
      top: 50%;
      transform: translateY(-1px);
    }
  
    .slick-next {
      top: 50%;
      right: 0;
      transform: translateY(-38px);
    }
  }
}

// .slider-flechas {
//   padding-top: $height-flechas-slick;
// }

// .slider-seguros {
//   @include fixSlick3col;

//   .slick-track {
//     display: flex;
//   }

//   .slick-slide {
//     height: inherit;
//   }

//   .tarjeta {
//     height: 100%;
//   }
// }

// .slider-testimoniales {
//   .slick-list {
//     padding: 0 30px;
//   }

//   .slick-track {
//     padding-top: 0;
//   }

//   &.noSlick {
//     .slick-track {
//       transform: translate3d(0, 0, 0) !important;
//     }
//   }
// }

// .slider-pasos {
//   padding-bottom: 30px;

//   .slick-list {
//     padding: 0 0;
//   }

//   .slick-track {
//     display: flex;
//   }

//   .slick-slide {
//     height: auto;
//   }

//   .tarjeta--pasos {
//     height: 100%;
//   }
// }

@include breakpoint('tablet-sm') {
  .slider-general {
    &.slide-events_hero {
      .slick-track {
        padding: 10px 0;
      }
    }
  }
}

@include breakpoint('tablet') {
  // .slider-pasos {
  //   .slick-arrow {
  //     top: 50%;
  //     transform: translateY(-50%);
  //   }

  //   .slick-prev {
  //     left: -30px;
  //   }

  //   .slick-next {
  //     right: -30px;
  //   }
  // }
}

@include breakpoint('desktop-xl') {
  // .slider-pasos {
  //   .slick-list {
  //     padding: 0 20px;
  //   }
  // }
}
