.sidebar-main {
  padding-top: 18px;

  &-heading {
    @include encabezadoH3;
    @include p22MackinacRegular;

    margin-bottom: 12px;
    color: $negro;
  }

  &-links {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    a {
      color: $negro;
      display: block;
      padding: 7px 0;
  
      &:hover {
        opacity: 0.5;
      }
    }

    .active a {
      @include foundersGroteskSemiBold;

      border-left: 2px solid $verde;
      color: $verde;
      padding-left: 5px;
    }
  }
}

.sidebar-secondary {
  padding-top: 32px;

  &-heading {
    @include p22MackinacRegular;

    color: $negro;
    line-height: 21px;
    margin-bottom: 12px;

    img {
      margin-right: 7px;
      max-height: 15px;
      position: relative;
      top: -1px;
      width: auto;
    }
  }

  &-links {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    a {
      display: block;
      padding: 7px 0;
      text-decoration: underline;
    }
  }
}

@include breakpoint('tablet') {
  .sidebar-main {
    padding-top: 0;

    &-heading {
      margin-bottom: 27px;
    }

    &-links {
      li {
        margin-bottom: 15px;
      }

      a {
        padding: 0;
      }
    }
  }

  .sidebar-secondary {
    padding-top: 0;

    &-links {
      margin-bottom: 10px;
      padding: 0;
    }
  }
}
