.bloque-404 {
  padding: 100px 0;
  position: relative;

  > .container {
    position: relative;
  }

  .icono-404 {
    align-items: center;
    background-color: $blanco;
    box-shadow: 0 0 20px rgba($negro, 0.08);
    display: flex;
    height: 80px;
    justify-content: center;
    margin-bottom: 15px;
    width: 80px;

    img {
      max-height: 33px;
      width: auto;
    }
  }

  h1 {
    margin-bottom: 10px;
  }

  &_descripcion {
    margin-bottom: 22px;

    a {
      color: $gris;
      text-decoration: underline;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@include breakpoint('tablet') {
  .bloque-404 {
    padding: 140px 0;

    &-container {
      margin: 0 auto;
      max-width: 80%;
    }

    .icono-404 {
      margin-bottom: 25px;
    }

    &_descripcion {
      margin-bottom: 32px;
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-404 {
    padding: 180px 0;

    &-container {
      max-width: 60%;
    }
  }
}
