.contact-wrapper {
  overflow: hidden;
  padding: $separacion-bloques-sm 0;
  position: relative;

  .container {
    position: relative;
  }

  .grid-lines {
    top: -$separacion-bloques-sm;
  }
}

.contact-form_title {
  @include encabezadoH2;

  color: $negro;
}

.contact-form_description {
  margin-bottom: 22px;
}

.contact-sidebar {
  padding-top: 30px;

  span {
    color: $negro;
    display: block;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  &_item:not(:last-child) {
    margin-bottom: 17px;
  }

  .contact-link {
    @include foundersGroteskSemiBold;

    line-height: 22px;
    text-decoration: underline;
  }

  .contact-social {
    display: inline-block;
    padding: 0 5px 5px;
    margin: 0px 3px;

    &:first-of-type {
      padding-left: 0px;
      margin-left: 0px;
    }

    img {
      width: 21px;
      height: 24px;
    }
  }
}

@include breakpoint('tablet') {
  .contact-wrapper {
    padding: $separacion-bloques-lg 0;
  
    .grid-lines {
      top: -$separacion-bloques-lg;
    }
  }

  .contact-sidebar {
    padding-top: 0;
  }
}

@include breakpoint('desktop-lg') {
  .contact-wrapper {
    padding: $separacion-bloques-xl 0;
  
    .grid-lines {
      top: -$separacion-bloques-xl;
    }
  }
}
