.bloque-paginacion {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  ul {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active a {
      background-color: $verde;
      color: $blanco;

      &:hover {
        background-color: $fondo3;
        color: $verde;
      }
    }
  }

  a {
    @include foundersGroteskSemiBold;

    align-items: center;
    background-color: $blanco;
    border: 1px solid $verde;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    img {
      max-height: 10px;
      width: auto;
    }

    &:hover {
      background-color: $fondo3;
      color: $verde;
    }
  }
}


@include breakpoint('tablet') {
  .bloque-paginacion {
    a {
      height: 45px;
      width: 45px;
    }
  }
}
