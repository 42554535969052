.grid-lines-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  .container {
    height: 100%;
    position: relative;
  }

  .grid-lines {
    display: flex;
  }

  .grid-lines__line {
    height: 100%;

    &:not(:nth-child(1)):not(:nth-child(2)) {
      display: none;
    }
  }
}

@include breakpoint('desktop-lg') {
  .grid-lines-container {
    .grid-lines__line:not(:nth-child(1)):not(:nth-child(2)) {
      display: block;
    }
  }
}
